export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";


export function getUsers(data) {
    return {
        payload: data,
        type: GET_USERS,
    };
}

export function getUsersSuccess(data) {
    return {
        payload: data,
        type: GET_USERS_SUCCESS,
    };
}
