import api from "../../api/index";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    GET_USERS,
    getUsersSuccess,
} from "../actions/Users.actions";
import { fetchUsers } from "../../api/users.api";


export function* getUsersSaga(action) {
    try {
        const response = yield call(
            api.usersApi.fetchUsers,
            action.payload
        );
        yield put(getUsersSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchGetUsers() {
    yield takeEvery(GET_USERS, getUsersSaga);
}

export default function* usersSagas() {
    yield all([
        watchGetUsers()
    ]);
}