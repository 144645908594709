import pickInterpolatedVars from "./pickInterpolatedVars";


const config = {
  oktalogout: process.env.REACT_APP_OKTALOGOUT,
  jll_url: process.env.REACT_APP_JLL_BASE_URL,
  OdsAzureSpidercrmApiAPIMToken: process.env.REACT_APP_OdsAzureSpidercrmApiAPIMToken
};

const aeroConfig = {
  ...config,
  ...pickInterpolatedVars((window).jllConfig)
};

export default aeroConfig;
