import {BASE_URL, AuthHeaders, PublicApiUri, PublicApiKey, SpiderApiUri} from "./config";
import axios from "axios";

export const fetchUsers = async (data) => {
    const searchTerm = data && data.value ? data.value : '';
    const response = await axios.get(
        `${BASE_URL}/users/?query=${searchTerm}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );
    return response.data;
};
